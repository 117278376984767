<template>
  <div v-if="$can('update','jobs')">
    <b-card>
      <form v-on:submit.prevent="formSubmitted" enctype="multipart/form-data">
      <!-- accoint details tab -->
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Job Details
                </h5>
                <small class="text-muted" v-if="form.jobId">
                  Job ID : {{ form.jobId }}
                </small>
              </b-col>

              <b-col
                md="4"
                xl="4"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Job Skill *"
                  label-for="jobTitle"
                >
                    <b-form-input
                      id="job-title"
                      v-model="form.jobTitle"
                      class="text-capitalize"
                      type="text"
                      placeholder="FPNA/Python Developer/Java Developer"
                      ref="title"
                    />
                    <small v-if="!form.jobTitle" class="text-danger">{{ error.title }}</small>
                  
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                xl="3"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Position Type *"
                  label-for="position"
                >
                  
                    <b-form-group ref="position">
                      <v-select
                        v-model="form.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="jobTypeOptions"
                        :reduce="(option) => option.title"
                      />
                    </b-form-group>
                  <small v-if="!form.type"  class="text-danger">{{ error.type }}</small>
                  
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                xl="3"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Job Role"
                  label-for="jobRole"
                >
                    <b-form-input
                      id="job-role"
                      v-model="form.role"
                      class="text-capitalize"
                      type="text"
                      placeholder="Job Role"
                      ref="title"
                    />
                    
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Job Band/Job Level"
                  label-for="jobBand"
                >
                    <b-form-input
                      id="job-band"
                      v-model="form.jobBand"
                      class="text-capitalize"
                      type="text"
                      placeholder="Job Band"
                      ref="title"
                    />
                   
                </b-form-group>
              </b-col>
              
              <b-col
                md="3"
                xl="3"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Client *"
                  label-for="client"
                >
                  
                    <b-form-group ref="client">
                      <v-select
                        v-model="form.clientSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="clients"
                        
                      />
                    </b-form-group>
                  <small v-if="!form.clientSelected"  class="text-danger">{{ error.client }}</small>
                  
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                xl="3"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Job Domain *"
                  label-for="jobDomain"
                >
                
                  <b-form-group>
                    <v-select
                      v-model="form.domainSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="domains"
                      ref="domain"
                      style="overflow-x: visible"
                    />
                  </b-form-group>
                  <small v-if="!form.domainSelected" class="text-danger">{{ error.domain }}</small>
                
                </b-form-group>
              </b-col>
              
              <b-col
                md="3"
                xl="3"
                xs="4"
                class="mb-1"
              >
                <b-form-group
                  label="Point Of Contact *"
                  label-for="poc"
                >
                    <b-form-input
                      id="poc"
                      v-model="form.poc"
                      class="text-capitalize"
                      type="text"
                      placeholder="Name of PoC"
                      :state="pocState"
                    />
                    <small v-if="this.error.poc" class="text-danger"> {{ error.poc }}</small>
                </b-form-group>
              </b-col>
              
              <b-col
                md="3"
                xl="3"
                xs="6"
                class="mb-1"
              v-if="form.type === 'Contractual'">
                <b-form-group
                  label="Payroll *"
                  label-for="payroll"

                >
                    <v-select
                      v-model="form.payroll"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="payrollOptions"
                      ref="payroll"
                      style="overflow-x: visible"
                      :reduce="(option) => option.title"
                    />
                    <small v-if="this.error.payroll" class="text-danger"> {{ error.payroll }}</small>
                </b-form-group>
              </b-col>
              
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                
                <b-form-group
                  label="Category *"
                  label-for="category"
                  
                >
                    <v-select
                      v-model="form.category"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="categoryOptions"
                      ref="category"
                      style="overflow-x: visible"
                      :reduce="(option) => option.title"
                    />
                    <small v-if="this.error.category" class="text-danger"> {{ error.category }}</small>
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                
                <b-form-group
                  label="Vertical *"
                  label-for="vertical"
                  
                >
                    <v-select
                      v-model="form.vertical"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="verticalOptions"
                      ref="vertical"
                      style="overflow-x: visible"
                      :reduce="(option) => option.title"
                    />
                    <small v-if="this.error.vertical" class="text-danger"> {{ error.vertical }}</small>
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
              <b-form-group
                  label="Target Date *"
                  label-for="targetDate"
                  
                >
                    <b-form-datepicker
                      id="targetDate"
                      v-model="form.targetDate"
                      class="mb-1"
                    />
                  
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Notice Period *"
                  label-for="noticePeriod"
                >
                  <v-select
                      id="notice-period"
                      v-model="form.noticePeriod"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="noticePeriod"
                      ref="np"
                    />
                    <!--<b-form-input
                      id="notice-period"
                      v-model="form.noticePeriod"
                      type="number"
                      ref="np"
                      placeholder="Number of Days"
                    />-->
                    <small v-if="!form.noticePeriod" class="text-danger">{{ error.noticePeriod }}</small>
                 
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="No of Positions *"
                  label-for="noOfPostion"
                >
                  
                    <b-form-input
                      id="noOfPositions"
                      v-model="form.noOfPositions"
                      type="number"
                      ref="nop"
                      placeholder="Number of Vacancies"
                    />
                    <small v-if="!form.noOfPositions" class="text-danger">{{ error.noOfPosition }}</small>
                 
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Min Experience *"
                  label-for="min-exp"
                >
                    <b-form-input
                      id="min-exp"
                      v-model="form.minExp"
                      type="number"
                      step=".01"
                      placeholder="Exp in years"
                      ref="minexp"
                    />
                    <small v-if="!form.minExp" class="text-danger">{{ error.minExp }}</small>
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Max Experience *"
                  label-for="max-exp"
                >                
                    <b-form-input
                      id="max-exp"
                      v-model="form.maxExp"
                      type="number"
                      step=".01"
                      ref="maxexp"
                      placeholder="Exp in years"
                    />
                    <small v-if="!form.maxExp" class="text-danger">{{ error.maxExp }}</small>
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Minimum CTC *"
                  label-for="minCTC"
                >
                  
                    <b-form-input
                      id="min-ctc"
                      v-model="form.minCTC"
                      type="number"
                      step=".01"
                      ref="minctc"
                      :placeholder="form.type === 'Contractual' && form.vertical === 'Infra Industry' && form.category === 'Blue Collar' && form.payroll === 'Sattva Payroll' ? 'Monthly salary': 'Enter Amount in lacs'"
                    />
                    <small v-if="!form.minCTC" class="text-danger">{{ error.minCTC }}</small>
                 
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Maximum CTC *"
                  label-for="minCTC"
                >
                  
                    <b-form-input
                      id="max-ctc"
                      v-model="form.maxCTC"
                      type="number"
                      step=".01"
                      ref="maxctc"
                      :placeholder="form.type === 'Contractual' && form.vertical === 'Infra Industry' && form.category === 'Blue Collar' && form.payroll === 'Sattva Payroll' ? 'Monthly salary': 'Enter Amount in lacs'"
                    />
                    <small v-if="!form.maxCTC" class="text-danger">{{ error.maxCTC }}</small>
                 
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                xl="6"
                xs="6"
                class="mb-1"
              >
              
                <b-form-group
                  label="Desired/Mandatory Skills *"
                  label-for="desiredSkills"
                >
                  <b-form-input
                    v-model="form.skills"
                    placeholder="Enter Skills Comma Separated"
                    type="text"
                    ref="skill"
                    @input="validateDesiredSkills"
                  />
                  <small v-if="!form.skills || error.skills" class="text-danger">{{ error.skills }}</small>
                </b-form-group>
              </b-col>
              
              
            </b-row>
          
        
          <div>
            
            <b-row>
              <b-col
                md="6"
                xl="6"
                xs="6"
                class="mb-1"
              >
              <h5 class="mb-1 mt-1">
              Account Manager
            </h5>
                <b-form-group
                  label="Account Mgr/Team Lead *"
                  label-for="accountMgr"
                >
                  <v-select
                      v-model="form.mgrSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      
                      :options="accountMgr"
                      ref="accMgr"
                    />
                  <small v-if="!form.mgrSelected" class="text-danger">{{ error.accMgr }}</small>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                xl="6"
                xs="6"
                class="mb-1"
              >
              <h5 class="mb-1 mt-1">
              Sourcer
            </h5>
                <b-form-group
                  label="Sourcer *"
                  label-for="sourcer"
                >
                  <v-select
                      v-model="form.sourcerSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      multiple
                      :options="recruiters"
                      :close-on-select="false"
                      ref="sourcer"
                    />
                  <small v-if="!form.sourcerSelected" class="text-danger">{{ error.sourcer }}</small>
                </b-form-group>
              </b-col>
            </b-row>
          </div>   
          <div>
            <h5 class="mb-1 mt-1">
              Location & Recruiters
            </h5>
            <b-row>
            <b-col
              md="6"
              xl="6"
              xs="6"
              class="mb-2"
            >
              <b-form-group
                label="City *"
                label-for="city"
              >
                  
                  <b-form-group>
                    <v-select
                      v-model="form.citySelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :close-on-select="false"
                      multiple
                      :options="cities"
                      ref="city"
                      :reduce="(option) => option.title"
                    />
                  </b-form-group>
                  <small v-if="!form.citySelected" class="text-danger">{{ error.city }}</small>
               
              </b-form-group>
            </b-col>  
            <b-col
              md="6"
              xl="6"
              xs="6"
              class="mb-1"
            > 
            
              <b-form-group
                label="Recruiters *"
                label-for="recruiters"
              >
                <v-select
                  v-model="form.recruitersSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :close-on-select="false"
                  label="title"
                  :options="recruiters"
                  ref="recruit"
                />
              <small v-if="!form.recruitersSelected" class="text-danger">{{ error.recruiter }}</small>
              
              </b-form-group>
              
              
            </b-col>
          </b-row>
          </div>
          <div>
            <h5 class="mb-1 mt-1">
              Job Description *
            </h5>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                
                <!--<small class="text-muted">Enter Job Description</small>-->
              </b-col>
              <b-col
                cols="12"
                class="mb-2"
              >
                <quill-editor
                  v-model="form.jdContent"
                  :options="snowOption"
                  ref="jd"
                />
                <small class="text-danger" v-if="!form.jdContent || form.jdContent.length < 200">{{ error.jd || 'Job description should be of atleast 200 characters' }}</small>
              </b-col>
              
              <b-col
                cols="6"
                xs="12"
                class="mb-2"
              >
              <div>
                  <!-- <div style="float:left">
                    <b-card-text class="mb-1">
                      Is JD Available?
                    </b-card-text>
                    <b-form-checkbox
                      v-model="isJDchecked"
                      checked="true"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                    />
                  </div> -->
                </div>
                <b-form-file
                  name="jdFile"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change='openFile'
                  type="file"
                  v-model="form.file"
                  accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, text/plain, application/rtf, text/markdown, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                />
                <small class="text-danger">{{ error.file }}</small>
                <small v-if="form.file && form.file.size > 1600000" class="text-danger"> File Size Should be less than 1.5 mb </small>
              </b-col>

            </b-row>
          </div>
          
          <div>
            <b-row>
              <b-col
              md="12"
              xl="12"
              xs="12"
              class="mb-2"
              >
              <b-button variant="relief-success" value="submit" id="submit" type="submit" width="100%">Submit</b-button>
              
              </b-col>
            </b-row>
          </div>

      </form>
    </b-card>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import moment from 'moment'
import JobService from '../../../services/job-service'
import UserService from '@/services/user-service'
import jobTimelineService from '@/services/job-timeline'
import clientService from '@/services/client-service'
import domainService from '@/services/domain-service'
import locationService from '@/services/location-service'
import adminConfigService from '@/services/admin-config-service'

import {
  BCard,
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormFile,
  BFormTextarea,
} from 'bootstrap-vue'
//import { required, email } from '@validations'
// import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BCard,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    vSelect,
    quillEditor,
    BFormFile,
    BFormTextarea,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      form:{
        jobId: '',
        id: '',
        jobTitle: '',
        band: '',
        role: '',
        clientSelected: '',
        domainSelected: '',
        poc: '',
        targetDate: '',
        minExp: '',
        maxExp: '',
        minCTC: '',
        maxCTC: '',
        noticePeriod: '',
        noOfPositions: '',
        skillsSelected: '',
        citySelected: '',
        recruitersSelected: '',
        jdContent: '',
        notes: '',
        file: '',
        mgrSelected: '',
        sourcerSelected: '',
        type: '',
        payroll: '',
        category: '',
        vertical: ''
      },
      promiseFulfilled: false,
      promiseRejected: false,
      snowOption: {
        theme: 'snow',
      },
      id: '',
      error: {
        title: '',
        client: '',
        domain: '',
        minExp: '',
        maxExp: '',
        minCTC: '',
        maxCTC: '',
        noticePeriod: '',
        noOfPositions: '',
        skills: '',
        city: '',
        recruiter: '',
        sourcer: '',
        jd: '',
        accMgr: '',
        notes: '',
        type: '',
        payroll: '',
        category: '',
        vertical: ''
      },
      clients: [],
      domains:[],
      //clients: [{"title":"AXA XL"},{"title":"Accenture"},{"title":"AffinityX"},{"title":"Amazon"},{"title":"Aristocrat"},{"title":"Auto FLow"},{"title":"Concentrix"},{"title":"Course5i"},{"title":"DYNINNO"},{"title":"Deloitte"},{"title":"EXL"},{"title":"Early Salary"},{"title":"Evalueserve"},{"title":"Finaxar"},{"title":"GVR"},{"title":"Genpact"},{"title":"Goldman Sachs"},{"title":"Google"},{"title":"Hexaware"},{"title":"ICICI Housing"},{"title":"IndusInd Bank"},{"title":"Infosys"},{"title":"Intigrity"},{"title":"KPMG Global Services"},{"title":"LTI"},{"title":"LTI RPO"},{"title":"Markets & Markets"},{"title":"Motilal Oswal"},{"title":"Mphasis"},{"title":"OYO Life"},{"title":"Ola Electric"},{"title":"Sapient"},{"title":"Sattva Human Pvt Ltd"},{"title":"Sears"},{"title":"Successive Technologies"},{"title":"Tech Mahindra"},{"title":"Teleperformance"},{"title":"UCB Pharma"},{"title":"UPGRAD"},{"title":"WNS Analytics & BPM"},{"title":"Wipro"},{"title":"Xceedance"},{"title":"eClinicalWorks"}],
      //domains: [{ title: 'IT' }, { title: 'Finance' }, { title: 'Marketing' }, { title: 'Sales' }, { title: 'ITES/BPO' }, { title: 'Finance N Accounting'}, { title: 'Consulting' },{ title: 'Research' },{ title: 'Analytics' }, { title: 'Digital Transformation/Process Excellence/Quality' },{ title: 'EV/Battery' },{ title: 'HROPS' }, { title: 'Talent Acquistion'}, { title: 'HRBP' }],
      recruiters: [],
      accountMgr: [],
      sourcer: [],
      cities: [],
      fcmToken: '',
      noticePeriod: [{title: "Immediate", value: 0}, {title: "0-15", value: 15}, {title: "0-30", value: 30},{title: "0-45", value: 45},{title: "0-60", value: 60}, {title: "0-90", value: 90}],
      fileData: '',
      jobTypeOptions: [{title: "Full Time"}, {title: "Contractual"}, {title: "Fixed Term"}, {title: "Cost Plus"}],
      payrollOptions: [{title: "Client Payroll"}, {title: "Sattva Payroll"}, {title: "Third Party"}],
      categoryOptions: [{title: "White Collar"}, {title: "Blue Collar"}],
      verticalOptions: [{title: "Service Industry"}, {title: "Infra Industry"}]

      //isJDchecked: true,
    }
  },
  mounted() {
    if(!this.$can('update','jobs')) {
        return
    }
    adminConfigService.getJobsDropdownList().then(res=>{
        this.jobStatusOptions = res.data.data.jobStatusList
        this.jobTypeOptions = res.data.data.jobTypeList
        this.categoryOptions = res.data.data.categoryList
        this.payrollOptions = res.data.data.payrollList
        this.verticalOptions = res.data.data.verticalList
    })
  },
  computed: {
    pocState() {
        let regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
         return this.error.poc = this.form.poc && !regName.test(this.form.poc) ? 'Please Enter Full Name' : ''
    }
  },
  created() {

    this.id = this.$route.params.id
    console.log(this.id)
    
    JobService.getEditJob(this.id).then(res => {
        console.log(res.data)
        this.recruitersAssigned = res.data.recruiters;
        this.account_mgrAssigned = res.data.account_mgr;
        console.log('AM', res.data.account_mgr)
        this.sourcerAssigned = res.data.sourcer;
        console.log('S',this.sourcerAssigned)
        this.fileData = res.data.attachments;
        if(res.status === "OK") {
            const jid = `SHJ-${(res.data.id + 1000)}`
            
            this.recruitersAssigned = JSON.parse(this.recruitersAssigned.replace(/(\w+:)|(\w+ :)/g, function(matchedStr) {
              return '"' + matchedStr.substring(0, matchedStr.length - 1) + '":';
            }))
            if (this.sourcerAssigned) {
              this.sourcerAssigned = JSON.parse(this.sourcerAssigned.replace(/(\w+:)|(\w+ :)/g, function(matchedStr) {
                return '"' + matchedStr.substring(0, matchedStr.length - 1) + '":';
              }))
              console.log('in sourccer', this.sourcerAssigned)
            }

            this.account_mgrAssigned = JSON.parse(this.account_mgrAssigned.replace(/(\w+:)|(\w+ :)/g, function(matchedStr) {
              return '"' + matchedStr.substring(0, matchedStr.length - 1) + '":';
            }))
            console.log('AM assigned', this.account_mgrAssigned)

            const client = JSON.parse(res.data.client.replace(/(\w+:)|(\w+ :)/g, function(matchedStr) {
              return '"' + matchedStr.substring(0, matchedStr.length - 1) + '":';
            }))
            const domain = JSON.parse(res.data.domain.replace(/(\w+:)|(\w+ :)/g, function(matchedStr) {
              return '"' + matchedStr.substring(0, matchedStr.length - 1) + '":';
            }))
            var list = res.data.locations.split(",")
            console.log('locations: ', list)
            var citiesSelectedList = [], city;
            for (let i = 0; i < list.length; i++) {
                city = list[i]
                console.log('location: ', city)
                citiesSelectedList.push(city);
            }
            console.log(res.data.notice_period)
            
            const notice_period = this.noticePeriod.filter(item => item.value === res.data.notice_period)
            console.log(res.data.target_date)
            console.log(client, domain)
             this.form = {
                jobTitle : res.data.position_name,
                jobId : res.data.job_id,
                id : res.data.id,
                role : res.data.role,
                band : res.data.band,
                clientSelected : client,
                domainSelected : domain,
                noOfPositions : res.data.no_of_vacancies,
                citySelected : citiesSelectedList,
                minCTC : res.data.min_ctc,
                maxCTC : res.data.max_ctc,
                minExp : res.data.min_exp,
                maxExp : res.data.max_exp,
                noticePeriod : notice_period,
                skills : res.data.desired_skills,
                mandatory_skills : res.data.mandatory_skills,
                jdContent : res.data.job_description,
                poc : res.data.poc,
                recruitersSelected : this.recruitersAssigned,
                mgrSelected : this.account_mgrAssigned,
                sourcerSelected: this.sourcerAssigned,
                file : res.data.attachments,
                status : res.data.status,
                created_by : res.data.created_by,
                updated_by : res.data.updated_by,
                targetDate: res.data.target_date,
                type: res.data.type, 
                payroll: res.data.payroll,
                category: res.data.category,
                vertical: res.data.vertical
            };
            console.log('form',this.form)
        }
        else if ((res.status === 401 ) || (res.status === 403 )) {
            window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
        } else {
          this.form.jobId = res
        }
      });
    
    // UserService.getAllUsersDd().then( res => {
    //   if(res.status === "OK") {
    //     console.log("in if")
    //     this.recruiters = res.data.filter(item => item.role === "talent-advisor-sourcer" || item.role === "account-manager" || item.role === "talent-advisor");
    //     this.accountMgr = res.data.filter(item => item.role === "account-manager");
    //     this.sourcer = res.data.filter(item => item.role === "talent-advisor-sourcer" || item.role === "account-manager");
        
    //     console.log(this.recruiters)
    //     console.log(this.accountMgr)
    //     console.log(this.sourcer)
    //   }
    // }).then( d => {
    // console.log(this.recruiters.length, !this.recruiters.length)
    // if ( !this.recruiters.length && !this.accountMgr.length) {
        
    // }
    // })
    UserService.getAllRecruitersList().then(res =>{
        console.log('recruiters ',res.data)
        this.recruiters = res.data.filter(item => item.status === 'ACTIVE')
    })
    UserService.getAllManagersList().then(res =>{
      console.log('managers ',res.data)
      this.accountMgr = res.data.filter(item => item.status === 'ACTIVE')
    })
    clientService.getClients().then(res => {
      this.clients = res.data
      console.log(this.clients)
    })
    domainService.getDomains().then(res => {
      this.domains = res.data
      console.log(this.domains)
    })
    locationService.getAll().then(res => {
      console.log(res.data)
      this.cities = res.data
    })

  },
  destroyed() {
    //window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    formSubmitted() {
      console.log('inside submit')
      const validation = this.validationForm();
      console.log(validation)
      
      if(validation) {
        const formData = new FormData()
        console.log('cities selected: ', this.form.citySelected)
        const cities = this.form.citySelected.toString()
        console.log('recruiter',this.form.recruitersSelected)
        const rid = JSON.parse(JSON.stringify(this.form.recruitersSelected)).map(({ id }) => id);
        let sid = ''
        
        if(this.form.sourcerSelected) {
          console.log('sourcer', this.form.sourcerSelected)
          sid = JSON.parse(JSON.stringify(this.form.sourcerSelected)).map(({ id }) => id);
          console.log('sid', sid)
        }
        console.log('mgr',this.form.mgrSelected)

      
        const aid = [this.form.mgrSelected.id];
        console.log('aid', aid, this.form.mgrSelected)
        console.log("accmgr",aid, rid, sid )
        let all_id = [...rid, ...aid, ...sid]
        console.log(all_id)
        let uniq_id = [...new Set(all_id)];
        console.log(uniq_id)
        console.log(all_id, uniq_id)

        const userData = JSON.parse(localStorage.getItem('userData'));

        const user = `[{"title" : "${userData.first_name} ${userData.last_name}", "emp_id": "${userData.emp_id}"}]`
        const username = `${userData.first_name} ${userData.last_name}`
        console.log("notice period", this.form.noticePeriod)
        console.log("notice period", typeof(this.form.noticePeriod))
        const recruiters = JSON.stringify(this.form.recruitersSelected)
        const sourcer = JSON.stringify(this.form.sourcerSelected) ? JSON.stringify(this.form.sourcerSelected) : ''
        console.log(this.form.mgrSelected);
        
        //let mgr = JSON.stringify(this.form.mgrSelected)
        let mgr = this.form.mgrSelected
        console.log(this.isArray(mgr));
        if(!this.isArray(mgr)) {
          mgr = '[' + JSON.stringify(mgr) + ']'
        } else {
          mgr = JSON.stringify(mgr)
        }
        console.log('AM mgr', mgr)
        //console.log("date",this.form.targetDate)
        const client = JSON.stringify(this.form.clientSelected).includes('[') ? JSON.stringify(this.form.clientSelected) : '[' + JSON.stringify(this.form.clientSelected) + ']' 
        const domain = JSON.stringify(this.form.domainSelected).includes('[') ? JSON.stringify(this.form.domainSelected) : '[' + JSON.stringify(this.form.domainSelected) + ']' 
        formData.append('position_name', this.form.jobTitle)
        formData.append('role', this.form.role)
        formData.append('band', this.form.jobBand)
        formData.append('client', client)
        formData.append('domain', domain)
        formData.append('no_of_vacancies', this.form.noOfPositions)
        formData.append('locations', cities)
        formData.append('target_date', this.form.targetDate)
        formData.append('min_ctc', this.form.minCTC)
        formData.append('max_ctc', this.form.maxCTC)
        formData.append('min_exp', this.form.minExp)
        formData.append('max_exp', this.form.maxExp)
        if(this.form.noticePeriod.value === undefined) {
          formData.append('notice_period', this.form.noticePeriod[0].value)
        }
        else {
          formData.append('notice_period', this.form.noticePeriod.value)
        }
        formData.append('desired_skills', this.form.skills)
        
        formData.append('job_description', this.form.jdContent)
        formData.append('poc', this.form.poc)
        formData.append('recruiters', recruiters)
        formData.append('sourcer', sourcer)
        formData.append('account_mgr', mgr)
        
        formData.append('status', this.form.status)
        formData.append('updated_by', user)
        formData.append('type', this.form.type)
        formData.append('payroll', this.form.payroll)
        formData.append('category', this.form.category)
        formData.append('vertical', this.form.vertical)
        //formData.append('notes', this.form.notes)
        if(this.form.file ) {
          formData.append('jdFile', this.form.file )
        }
        
        JobService.updateJob(this.id, formData).then( res => {
          console.log("addjob", res)
          
          if(res.status === "OK") {

            const userData = JSON.parse(localStorage.getItem('userData'));

            const username = `${userData.first_name} ${userData.last_name}`
          //this.id = res.data.id;
          //console.log("jobid", job_id)
          
            const timelineData = {
              title: `${this.form.jobId} - Job Edited`,
              description: `Some fields were changed`,
              type: "info",
              createdBy: username,
              jobId: this.form.id,
              candidate_id: null
            }
            console.log(timelineData);
            
            jobTimelineService.create(timelineData).then(res => {
              console.log("timeline", res)
            })
              this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Job Updated',
                          icon: 'EditIcon',
                          variant: 'success',
                        },
                                    
              })  
              //this.$router.push({ name: 'apps-jobs'})
               this.$router.push({ path: '/apps/view-job/' + this.form.id })                    
        } else if ((res.status === 401 ) || (res.status === 403 )) {
            window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
        } 
        else {
          this.form.jobId = res
            this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Job Update Failed',
                        icon: 'EditIcon',
                        variant: 'danger',
                      },
            })    
            // this.$router.push({ name: 'apps-jobs'}) 
          }
          
          
        })

      }
      
      
    },
    openFile (event) {
        this.form.file = event.target.files[0];
        console.log("file", this.form.file.size)
        
    },
    validationForm() {
      this.error = {
        title: '',
        client: '',
        domain: '',
        minExp: '',
        maxExp: '',
        minCTC: '',
        maxCTC: '',
        noticePeriod: '',
        noOfPositions: '',
        skills: '',
        city: '',
        recruiter: '',
        sourcer: '',
        jd: '',
        accMgr: '',
        notes: '',
        type: '',
        payroll: '',
        category: '',
        vertical: ''
      }
      if (!this.form.jobTitle) {
        this.error.title="Title is Mandatory"
        this.$nextTick(() => {
          this.$refs.title.focus()
        })
        return false
      }
      if (!this.form.clientSelected) {
        this.error.client="Client is Mandatory"
        
        return false
      }
      if (!this.form.domainSelected) {
        this.error.domain="Domain is Mandatory"
        
        return false
      }
      if (!this.form.payroll && this.form.type === 'Contractual') {
        this.error.payroll="Payroll is Mandatory for Contractual Jobs!"
        return false
      }
      if (!this.form.category ) {
        this.error.category="Category is Mandatory!"
        return false
      }
      if (!this.form.vertical ) {
        this.error.vertical="Vertical is Mandatory!"
        return false
      }
      
      let regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
      if(this.form.poc && !regName.test(this.form.poc))
      {
        return false
      }
      if (!this.form.minExp) {
        this.error.minExp="Min Exp is Mandatory"
        this.$nextTick(() => {
          this.$refs.minexp.focus()
        })
        return false
      }
      if (!this.form.maxExp) {
        this.error.maxExp="Max Exp is Mandatory"
        this.$nextTick(() => {
          this.$refs.maxexp.focus()
        })
        return false
      }
      if (!this.form.minCTC) {
        this.error.minCTC="Min CTC is Mandatory"
        this.$nextTick(() => {
          this.$refs.minctc.focus()
        })
        return false
      }
      if (!this.form.maxCTC) {
        this.error.maxCTC="Max CTC is Mandatory"
        this.$nextTick(() => {
          this.$refs.maxctc.focus()
        })
        return false
      }
      if (!this.form.noticePeriod) {
        this.error.noticePeriod="Notice Period is Mandatory"
        this.$nextTick(() => {
          this.$refs.np.focus()
        })
        return false
      }
      if (!this.form.noOfPositions) {
        this.error.noOfPositions="No Of Positions is Mandatory"
        this.$nextTick(() => {
          this.$refs.nop.focus()
        })
        return false
      }
      if (!this.form.skills || !this.validateDesiredSkills()) {
        this.error.skills="Skills is Mandatory"
        return false
      }
      if (!this.form.citySelected) {
        this.error.city="City is Mandatory"
        
        return false
      }
      if (!this.form.recruitersSelected) {
        this.error.recruiter="Recruiter is Mandatory"
        
        return false
      }
      if (!this.form.jdContent || (this.form.jdContent && this.form.jdContent.length < 200)) {
        this.error.jd="JD is Mandatory & should be atleast of 200 characters"
        
        return false
      }
      if (!this.form.mgrSelected) {
        this.error.accMgr="Account Manager/TL is Mandatory"
        
        return false
      }
      
      if(this.form.file && this.form.file.size > 1600000 ) {
        console.log('inside file validation')
        this.error.file = null
        if(this.form.file.size > 1600000) {
          console.log('inside file size check')
          this.error.file = "File should not be greater than 1.5MB"
        } 
        return false
      }
      return true
    },
    isArray(a) {
    return (!!a) && (a.constructor === Array);
    },
    isObject(a) {
    return (!!a) && (a.constructor === Object);
    },
    validateDesiredSkills() {
      this.error.skills = null
      console.log('in desired skill validation', this.form.skills)
      // const regex = /^(?:\s*\w+\s*,){2,}\s*\w+\s*$/;
      const regex = /^(\s*[\w\s]+,\s*){2,}\s*[\w\s]+,?\s*$/
      if(!regex.test(this.form.skills)) {
        this.error.skills = 'Alteast 3 skills required separated by comma'
      }
      return regex.test(this.form.skills);
    },
  },

}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/quill.scss';
  .repeater-form {
  overflow: hidden;
  transition: .35s height;
}

</style>
